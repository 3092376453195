/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { KTSVG, QUERIES, toAbsoluteUrl } from '../../../helpers'
import { useLayout } from '../../core'
import { Header } from './Header'
import { DefaultTitle } from './page-title/DefaultTitle'
import { Topbar } from './Topbar'
import './style.css'
import { getBalanceInfo } from './core/_requests'
import { BalanceInfoModel } from './core/_models'
import { useQuery } from 'react-query'
import { formatCurrency } from '../../../helpers/FunctionHelpers'

export function HeaderWrapper() {
  const [showMoney, setShowMoney] = useState<boolean>(false)
  const { config, classes, attributes } = useLayout()
  const { header, aside } = config
  const navigate = useNavigate();

  const { isFetching: isLoading, refetch, data: response } = useQuery(
    `${QUERIES.BALANCES}-info`,
    () => { return getBalanceInfo() },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  )
  let balanceInfo: BalanceInfoModel = {};

  if (response && response.status) {
    balanceInfo = response.data || {};
  }

  const redirectRecharge = () => {
    navigate('/recharge');
  }

  useEffect(() => {
    setInterval(refetch, 10000);
  }, [])

  return (
    <div
      id='kt_header'
      className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
      {...attributes.headerMenu}
    >
      <div
        className={clsx(
          classes.headerContainer.join(' '),
          'd-flex align-items-stretch justify-content-between'
        )}
      >
        {/* begin::Aside mobile toggle */}
        {aside.display && (
          <div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu'>
            <div
              className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
              id='kt_aside_mobile_toggle'
            >
              <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-2x mt-1' />
            </div>
          </div>
        )}
        {/* end::Aside mobile toggle */}
        {/* begin::Logo */}
        {!aside.display && (
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
            <Link to='/dashboard' className='d-lg-none'>
              <img alt='Logo' src={toAbsoluteUrl('/media/logos/fbotp_color.svg')} className='h-30px' />
            </Link>
          </div>
        )}
        {/* end::Logo */}

        {aside.display && (
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0 logo-otp'>
            <Link to='/' className='d-lg-none'>
              <img alt='Logo' src={toAbsoluteUrl('/media/logos/fbotp_color.svg')} className='h-30px' />
            </Link>
          </div>
        )}

        {/* begin::Wrapper */}
        <div className='d-flex align-items-stretch account-info justify-content-between flex-lg-grow-1'>
          {/* begin::Navbar */}

          <div className='row d-flex align-items-center detail'>
            <div className='col-3 col-sm-3 col-md-3 sale-title'>Tài khoản:</div>
            <div className='col-5 col-sm-5 col-md-5 font-weight-bold sale-number position-relative'
              style={{ color: '#005DB3', paddingRight: '45px' }}>
              {showMoney ? formatCurrency(balanceInfo.balance?.currency || 0, 0) : '************'}
              <i
                className={`fa fas ${showMoney ? 'fa-eye-slash' : 'fa-eye'} icon-5x position-absolute`}
                style={{ fontSize: '18px', right: '10px', top: '5px' }}
                onClick={() => setShowMoney(!showMoney)}
              ></i>
            </div>
            <div className='col-4 col-sm-4 col-md-4 font-weight-bold'>
              <button
                type='button'
                onClick={redirectRecharge}
                className='btn btn-primary font-weight-bold me-3 btn-border'>
                Nạp tiền
              </button>
            </div>
          </div>

          {header.left === 'menu' && (
            <div className='d-flex align-items-stretch' id='kt_header_nav'>
              {/* <Header /> */}
            </div>
          )}

          {header.left === 'page-title' && (
            <div className='d-flex align-items-center' id='kt_header_nav'>
              <DefaultTitle />
            </div>
          )}

          <div className='d-flex align-items-stretch flex-shrink-0'>
            <Topbar />
          </div>
        </div>
        {/* end::Wrapper */}
      </div>
    </div>
  )
}
