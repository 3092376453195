/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { MainRoutes } from './MainRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout, AuthPage, useAuth } from '../modules/auth'
import { App } from '../App'
import { CONSTANT } from '../../constant'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
  const { currentUser } = useAuth()
  let routes = (
    <>
      <Route path='auth/*' element={<AuthPage />} />
      <Route path='*' element={<Navigate to='/auth' />} />
    </>
  );

  if (currentUser) {
    routes = (
      <>
        <Route path='/*' element={<MainRoutes />} />
        <Route index element={<Navigate to='/services' />} />
      </>
    )
  }

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {routes}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }
