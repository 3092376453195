import moment from 'moment'
import { UserModel } from '../../app/modules/auth';
import { CONSTANT } from '../../constant';

function takeDecimalNumber(num: number | undefined, n: number) {
  if (num == undefined) {
    return '';
  }

  const base = 10 ** n;
  return `${Math.round(num * base) / base}`;
}

function fomatDecimalNumber(num: number | undefined, n: number) {
  if (num == undefined) {
    return '';
  }

  const base = 10 ** n;
  return Math.round(num * base) / base;
}

function formatCurrency(num: number | undefined, n: number) {
  if (num == undefined) {
    return '';
  }

  const currency = localStorage.getItem(CONSTANT.CURRENCY.KEY);

  if (currency == CONSTANT.CURRENCY.USD) {
    if ((num - parseInt(num.toString())) > 0) {
      return `$${formatMoneyWithConfig(num, 2, ',', '.')}`;
    }

    return `$${formatMoneyWithConfig(num, 0, ',', '.')}`
  }

  return `${formatMoneyWithConfig(num, 0, ',', '.')} VNĐ`
}

function formatMoney(num: number, n: number) {
  if (num == undefined) {
    return '';
  }

  const currency = localStorage.getItem(CONSTANT.CURRENCY.KEY);

  if (currency == CONSTANT.CURRENCY.USD) {
    if ((num - parseInt(num.toString())) > 0) {
      return formatMoneyWithConfig(num, n, ',', '.');
    }
  }

  return formatMoneyWithConfig(num, 0, ',', '.')
}

function formatMoneyRound(num: number, n: number) {
  if (num == undefined) {
    return '';
  }

  const currency = localStorage.getItem(CONSTANT.CURRENCY.KEY);

  if (currency == CONSTANT.CURRENCY.USD) {
    if ((num - parseInt(num.toString())) > 0) {
      return formatMoneyWithConfig(num, 2, ',', '.');
    }

    return formatMoneyWithConfig(num, 0, ',', '.')
  }

  let result = '';
  // num = Math.round(num * 10 ** n) / (10 ** n);

  if (num / 1000000 < 1) {
    result = `${formatMoneyWithConfig(num, 0, ',', '.')}`;
  } else if (num / 1000000000 < 1) {
    result = `${mathFloor(num, 6)} triệu`;
  } else if (num / 1000000000000 < 1) {
    result = `${mathFloor(num, 9)} tỷ`;
  } else {
    result = `${mathFloor(num, 12)} nghìn tỷ`;
  }

  return result
}

function formatMoneyRoundTotal(num: number, n: number) {
  if (num == undefined) {
    return '';
  }

  const currency = localStorage.getItem(CONSTANT.CURRENCY.KEY);

  if (currency == CONSTANT.CURRENCY.USD) {
    if ((num - parseInt(num.toString())) > 0) {
      return formatMoneyWithConfig(num, 2, ',', '.');
    }

    return formatMoneyWithConfig(num, 0, ',', '.')
  }

  let result = '';
  // num = Math.round(num * 10 ** n) / (10 ** n);

  if (num / 1000000 < 1) {
    result = `${formatMoneyWithConfig(num, 0, ',', '.')}`;
  } else if (num / 1000000000 < 1) {
    result = `${mathFloor(num, 6)} tr`;
  } else if (num / 1000000000000 < 1) {
    result = `${mathFloor(num, 9)} tỷ`;
  } else {
    result = `${mathFloor(num, 12)} nghìn tỷ`;
  }

  return result
}

function mathFloor(num: number, n: number) {
  const format = Math.floor(num / Math.pow(10, n - 1)) / 10;
  return format.toString().replace('\.', '\,');
}

function formatRevenueNorm(revenue: number) {
  if (revenue == undefined) {
    return '';
  }

  const currency = localStorage.getItem(CONSTANT.CURRENCY.KEY);

  if (currency == CONSTANT.CURRENCY.USD) {
    if ((revenue - parseInt(revenue.toString())) > 0) {
      return formatMoneyWithConfig(revenue, 2, ',', '.');
    }
  }

  return formatMoneyWithConfig(revenue, 0, ',', '.')
}

function formatIntWithDelimiter(num: string | number | undefined): string {
  if (typeof num === 'string' && !/.*[0-9].*/.test(num)) {
    return ''
  }

  num = num || 0;
  const currency = localStorage.getItem(CONSTANT.CURRENCY.KEY);

  if (currency == CONSTANT.CURRENCY.USD) {
    return formatCurrencyUSD(num.toString(), ',', '.');
  }

  return formatMoney(+((num + '').replace(/[^0-9]/g, '')), 0)
}

function formatMoneyWithConfig(num: number, n: number, s: string, c: string) {
  var re = '\\d(?=(\\d{' + (3) + '})+' + (n > 0 ? '\\D' : '$') + ')';
  const convertNum = num.toFixed(Math.max(0, ~~n));

  return (c ? convertNum.replace('.', c) : convertNum).replace(new RegExp(re, 'g'), '$&' + (s || ','));
}

function formatNumber(n: string, s: string) {
  return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, s)
}

function formatCurrencyUSD(inputValue: string, s: string, c: string) {
  if (inputValue === "") {
    return '';
  }

  if (inputValue.indexOf(c) >= 0) {
    let decimalPos = inputValue.indexOf(c);
    let leftSide = inputValue.substring(0, decimalPos);
    let rightSide = inputValue.substring(decimalPos);

    leftSide = formatNumber(leftSide, s);
    rightSide = formatNumber(rightSide, s);
    rightSide = rightSide.substring(0, 2);
    inputValue = `${leftSide}${c}${rightSide}`;
  } else {
    inputValue = formatNumber(inputValue, s);
  }

  return inputValue;
}


function formatMEY(num: number | undefined) {
  if (num == undefined) {
    return '';
  }

  return `${formatMoney(num, 0)} MEY`;
}

function formatCurrencyNumber(num: number | undefined) {
  const currency = '$';

  if (num == undefined) {
    return '';
  }

  return `${currency}${num}`;
}

function convertSalePercent(targetAchievedRatio: number) {
  return fomatDecimalNumber((targetAchievedRatio || 0) * 100, 0) || 0;
}

function getThPercent(revenue: number, norm: number) {
  if (!norm || norm == 0) return 0;

  const persent = convertSalePercent(revenue / norm);

  return formatMoneyWithConfig(persent, 0, ',', '.');
}

function getExcelName(role: string, dateType: string, funcType: string) {
  let dateString = '';

  switch (dateType) {
    case CONSTANT.TYPE_DATE.DAY:
      dateString = moment().format(CONSTANT.FORMAT_DATE.DDMMYYYY);

      break;
    case CONSTANT.TYPE_DATE.WEEK:
      const startOfWeek = moment().startOf('week').format(CONSTANT.FORMAT_DATE.DDMMYYYY);
      const endOfWeek = moment().endOf('week').format(CONSTANT.FORMAT_DATE.DDMMYYYY);
      dateString = `${startOfWeek}_${endOfWeek}`;

      break;
    case CONSTANT.TYPE_DATE.MONTH:
      dateString = moment().format(CONSTANT.FORMAT_DATE.MMYYYY);

      break;
  }

  let fileName = '';

  switch (funcType) {
    case CONSTANT.FUNC_TYPE.SUPER_ADMIN_PARTNER:
    case CONSTANT.FUNC_TYPE.CHIEF_OFFICE_PARTNER:
      fileName = `${role}_Doi_tac_${dateString}`;

      break;
    case CONSTANT.FUNC_TYPE.MANAGER_PARTNER:
    case CONSTANT.FUNC_TYPE.REPRESENTATIVE_OFFICE_PARTNER:
      fileName = `${role}_${dateType}_${dateString}`;

      break;
    case CONSTANT.FUNC_TYPE.REPRESENTATIVE_OFFICE_INSTALLMENT:
    case CONSTANT.FUNC_TYPE.MANAGER_INSTALLMENT:
    case CONSTANT.FUNC_TYPE.CHIEF_OFFICE_INSTALLMENT:
    case CONSTANT.FUNC_TYPE.SUPER_ADMIN_INSTALLMENT:
      fileName = `${role}_Doi_tac_qua_han_${dateString}`;

      break;
    case CONSTANT.FUNC_TYPE.SUPER_ADMIN_REGION:
      fileName = `Quan_ly_khoi_DS_Vung_mien_${dateString}`;

      break;
    case CONSTANT.FUNC_TYPE.SUPER_ADMIN_AREA:
      fileName = `Quan_ly_khoi_DS_Khu_vuc_${dateString}`;

      break;
    case CONSTANT.FUNC_TYPE.SUPER_ADMIN_OFFICE:
      fileName = `Quan_ly_khoi_DS_Van_phong_${dateString}`;

      break;
    case CONSTANT.FUNC_TYPE.CHIEF_OFFICE_AREA:
      fileName = `Quan_ly_vung_mien_DS_Khu_vuc_${dateString}`;

      break;
    case CONSTANT.FUNC_TYPE.CHIEF_OFFICE_OFFICE:
      fileName = `Quan_ly_vung_mien_DS_Van_phong_${dateString}`;

      break;
    case CONSTANT.FUNC_TYPE.MANAGER_OFFICE:
      fileName = `Quan_ly_khu_vuc_Van_phong_${dateString}`;

      break;
  }

  return fileName;
}

function getCurrency() {
  const currency = localStorage.getItem(CONSTANT.CURRENCY.KEY);

  return currency == CONSTANT.CURRENCY.USD ? ' ($)' : ' (đ)';
}

function downloadFileExcel(response: any, fileName: string) {
  const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}.xlsx`);
  document.body.appendChild(link);
  link.click();
  link.remove();
}

function getRevenueMonths() {
  let currency = localStorage.getItem(CONSTANT.CURRENCY.KEY);
  currency = currency == CONSTANT.CURRENCY.USD ? '$' : 'đ';

  return {
    currentMonth: `${moment().format(CONSTANT.FORMAT_DATE.MM_YYYY)}(${currency})`,
    oneMonthAgo: `${moment().add(-1, 'M').format(CONSTANT.FORMAT_DATE.MM_YYYY)}(${currency})`,
    twoMonthAgo: `${moment().add(-2, 'M').format(CONSTANT.FORMAT_DATE.MM_YYYY)}(${currency})`
  }
}

function getRevenueMonthsNoCurrency() {
  return {
    currentMonth: `${moment().format(CONSTANT.FORMAT_DATE.MM_YYYY)}`,
    oneMonthAgo: `${moment().add(-1, 'M').format(CONSTANT.FORMAT_DATE.MM_YYYY)}`,
    twoMonthAgo: `${moment().add(-2, 'M').format(CONSTANT.FORMAT_DATE.MM_YYYY)}`
  }
}

function getRegionRevenueDate() {
  let currency = localStorage.getItem(CONSTANT.CURRENCY.KEY);
  currency = currency == CONSTANT.CURRENCY.USD ? '$' : 'đ';

  const currentYear = moment().year();
  const currentQuarter = moment().quarter();
  let oneQuarterlyAgo = `Quý ${currentQuarter - 1}-${currentYear}`

  if (currentQuarter == 1) {
    oneQuarterlyAgo = `Quý 4-${currentYear - 1}`;
  }

  return {
    currentDay: `DS ${moment().format(CONSTANT.FORMAT_DATE.DD_MM_YYYY)}`,
    oneDayAgo: `DS ${moment().add(-1, 'd').format(CONSTANT.FORMAT_DATE.DD_MM_YYYY)}`,
    twoDayAgo: `DS ${moment().add(-2, 'd').format(CONSTANT.FORMAT_DATE.DD_MM_YYYY)}`,
    currentMonth: `DS ${moment().format(CONSTANT.FORMAT_DATE.MM_YYYY)}`,
    oneMonthAgo: `DS ${moment().add(-1, 'M').format(CONSTANT.FORMAT_DATE.MM_YYYY)}`,
    twoMonthAgo: `${moment().add(-2, 'M').format(CONSTANT.FORMAT_DATE.MM_YYYY)}`,
    currentQuarter: `DS Quý ${currentQuarter}-${currentYear}`,
    oneQuarterlyAgo: `DS ${oneQuarterlyAgo}`,
    monthNorm: `CT Tháng`,
    quarterNorm: `CT Quý`
  }
}

function getRevenueNormKey(month: string) {
  return `${CONSTANT.META_TYPE.REVENUE_NORM}_${month}_${moment().format('YYYY')}`
}

function checkIsAdministrator(currentUser?: UserModel) {
  // return currentUser?.is_administrator == CONSTANT.IS_ADMINISTRATOR.YES
  return false;
}

function getRevenuColor(index: number) {
  let color = '';

  try {
    switch (index % 4) {
      case 0:
        color = '#005DB3';

        break;
      case 1:
        color = '#01D4FE';

        break;
      case 2:
        color = '#19AC85';

        break;
      case 3:
        color = '#F2994A';

        break;
    }
  } catch (err) {
    color = '005DB3';
  }

  return color;
}

function getFirstMonthInQuarter(quarter: number, year: number) {
  let month = '01';

  switch (quarter) {
    case 1:
      month = '01';

      break;
    case 2:
      month = '04';

      break;
    case 3:
      month = '07';

      break;
    case 4:
      month = '10';

      break;
  }

  return `${month}-${year}`
}

function toLowerCaseSlug(str: string) {
  if (!str || str == '') return '';

  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  // Xóa khoảng trắng thay bằng ký tự -
  str = str.replace(/(\s+)/g, '-');

  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // Huyền sắc hỏi ngã nặng 
  str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // Â, Ê, Ă, Ơ, Ư

  return str;
}

function getTime(time: number) {
  if (!time) {
    return ''
  }

  return moment(new Date(time)).format(CONSTANT.DATETIME_FORMAT)
}

function unsecuredCopyToClipboard(text: string) {
  const textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    console.error('Unable to copy to clipboard', err);
  }
  document.body.removeChild(textArea);
}

function hideMail(mail: string) {
  const arr = mail.split('@')

  return `${arr[0].substring(0, arr[0].length - 5)}*****@${arr[1]}`
}

/**
 * 
 * @param date format DD-MM-YYYY
 */
function getFromDatetime(date: string) {
  return moment(`${date} 00:00:00`, `${CONSTANT.FORMAT_DATE.DD_MM_YYYY} HH:mm:ss`).toDate().getTime()
}

/**
 * 
 * @param date format DD-MM-YYYY
 */
function getToDatetime(date: string) {
  return moment(`${date} 23:59:59`, `${CONSTANT.FORMAT_DATE.DD_MM_YYYY} HH:mm:ss`).toDate().getTime()
}

function getStatusOTP(status: number) {
  let statusString = '';

  switch (status) {
    case CONSTANT.OTP_STATUS.PENDING:
      statusString = 'Chờ tin nhắn';
      break;
    case CONSTANT.OTP_STATUS.SCCESS:
      statusString = 'Thành công';
      break;
    case CONSTANT.OTP_STATUS.FAIL:
      statusString = 'Thất bại';
      break;
  }

  return statusString;
}

export {
  convertSalePercent,
  takeDecimalNumber,
  fomatDecimalNumber,
  formatCurrency,
  formatCurrencyNumber,
  formatMoney,
  formatMoneyRound,
  formatMoneyRoundTotal,
  formatCurrencyUSD,
  formatMEY,
  formatRevenueNorm,
  getExcelName,
  getCurrency,
  downloadFileExcel,
  getRevenueMonths,
  getRevenueMonthsNoCurrency,
  getRegionRevenueDate,
  formatIntWithDelimiter,
  formatMoneyWithConfig,
  checkIsAdministrator,
  getRevenuColor,
  getThPercent,
  getRevenueNormKey,
  getFirstMonthInQuarter,
  toLowerCaseSlug,
  getTime,
  unsecuredCopyToClipboard,
  hideMail,
  getFromDatetime,
  getToDatetime,
  getStatusOTP
}