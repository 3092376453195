import { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react'
import qs from 'qs'
import { ID, QueryResponseContextProps, QueryResponseDataProps, QueryState, QueryStateData } from './models'

function createResponseContext<T>(initialState: QueryResponseContextProps<T>) {
  return createContext(initialState)
}

function createResponseData<T>(initialState: QueryResponseDataProps<T>) {
  return createContext(initialState)
}

function isNotEmpty(obj: unknown) {
  return obj !== undefined && obj !== null && obj !== ''
}

// Example: page=1&items_per_page=10&sort=id&order=desc&search=a&filter_name=a&filter_online=false
function stringifyRequestQuery(state: QueryState): string {
  const pagination = qs.stringify(state, { filter: ['page', 'items_per_page'], skipNulls: true })
  const sort = qs.stringify(state, { filter: ['sort', 'order'], skipNulls: true })
  const search = isNotEmpty(state.search)
    ? qs.stringify(state, { filter: ['search'], skipNulls: true })
    : ''

  const filter = state.filter
    ? Object.entries(state.filter as Object)
      .filter((obj) => isNotEmpty(obj[1]))
      .map((obj) => {
        return `filter_${obj[0]}=${obj[1]}`
      })
      .join('&')
    : ''

  return [pagination, sort, search, filter]
    .filter((f) => f)
    .join('&')
    .toLowerCase()
}

function stringifyRequestData(state: QueryStateData): string {
  const search = isNotEmpty(state.search)
    ? qs.stringify(state, { filter: ['search'], skipNulls: true })
    : ''

  const filter = state.filter
    ? Object.entries(state.filter as Object)
      .filter((obj) => isNotEmpty(obj[1]))
      .map((obj) => {
        return `filter_${obj[0]}=${obj[1]}`
      })
      .join('&')
    : ''

  return [search, filter]
    .filter((f) => f)
    .join('&')
    .toLowerCase()
}


function parseRequestQuery(query: string): QueryState {
  const cache: unknown = qs.parse(query)
  return cache as QueryState
}

function calculatedGroupingIsDisabled<T>(isLoading: boolean, data: Array<T> | undefined): boolean {
  if (isLoading) {
    return true
  }

  return !data || !data.length
}

function calculateIsAllDataSelected<T>(data: Array<T> | undefined, selected: Array<ID>): boolean {
  if (!data) {
    return false
  }

  return data.length > 0 && data.length === selected.length
}

function groupingOnSelect(
  id: ID,
  selected: Array<ID>,
  setSelected: Dispatch<SetStateAction<Array<ID>>>
) {
  if (!id) {
    return
  }

  if (selected.includes(id)) {
    setSelected(selected.filter((itemId) => itemId !== id))
  } else {
    const updatedSelected = [...selected]
    updatedSelected.push(id)
    setSelected(updatedSelected)
  }
}

function groupingOnSelectAll<T>(
  isAllSelected: boolean,
  setSelected: Dispatch<SetStateAction<Array<ID>>>,
  data?: Array<T & { id?: ID }>
) {
  if (isAllSelected) {
    setSelected([])
    return
  }

  if (!data || !data.length) {
    return
  }

  setSelected(data.filter((item) => item.id).map((item) => item.id))
}

// Hook
function useDebounce(value: string | undefined, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )
  return debouncedValue
}

function getListPage(pageCount: number, currentPage: number) {
  const listPage: Array<{ label: string; page: number; active: boolean, 
    hideRight?: boolean, hideLeft?: boolean }> = [];
  listPage.push({ label: 'previous', page: currentPage == 1 ? 1 : currentPage - 1, active: false });

  if (pageCount <= 13) {
    for (let i = 1; i <= pageCount; i++) {
      listPage.push({ label: i.toString(), page: i, active: i == currentPage });
    }
  } else {
    for (let i = 1; i <= 3; i++) {
      listPage.push({ label: i.toString(), page: i, active: i == currentPage });
    }

    if (currentPage >= 6 && currentPage <= (pageCount - 5)) {
      listPage.push({ label: (currentPage - 1).toString(), page: currentPage - 1, active: false, hideLeft: true });
      listPage.push({ label: currentPage.toString(), page: currentPage, active: true });
      listPage.push({ label: (currentPage + 1).toString(), page: currentPage + 1, active: false, hideRight: true });
    } else if (currentPage >= 3 && currentPage < 6) {
      for (let i = 4; i <= currentPage + 1; i++) {
        listPage.push({ label: i.toString(), page: i, active: i == currentPage, hideRight: i == currentPage + 1 });
      }
    } else if (currentPage > (pageCount - 5) && currentPage < (pageCount - 1)) {
      for (let i = (currentPage - 1); i <= (pageCount - 3); i++) {
        listPage.push({ label: i.toString(), page: i, active: i == currentPage, hideLeft: i == (currentPage - 1) });
      }
    } else {
      const haft = Math.floor(pageCount / 2);
      listPage.push({ label: (haft - 1).toString(), page: haft - 1, active: false, hideLeft: true });
      listPage.push({ label: haft.toString(), page: haft, active: false });
      listPage.push({ label: (haft + 1).toString(), page: haft + 1, active: false, hideRight: true });
    }

    for (let i = pageCount - 2; i <= pageCount; i++) {
      listPage.push({ label: i.toString(), page: i, active: i == currentPage });
    }
  }

  listPage.push({ label: 'next', page: currentPage >= pageCount ? pageCount : currentPage + 1, active: false });

  return listPage;
}

export {
  createResponseData,
  createResponseContext,
  stringifyRequestQuery,
  stringifyRequestData,
  parseRequestQuery,
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  groupingOnSelect,
  groupingOnSelectAll,
  useDebounce,
  isNotEmpty,
  getListPage,
}
