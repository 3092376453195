import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'

const MainRoutes = () => {
  const ServicesPage = lazy(() => import('../modules/services/ServicesPage'))
  const AccountHistoriesPage = lazy(() => import('../modules/account-history/AccountHistoriesPage'))
  const OtpHistoriesPage = lazy(() => import('../modules/otp-history/OtpHistoriesPage'))
  const RechargePage = lazy(() => import('../modules/recharge/RechargePage'))
  const ApiInfoPage = lazy(() => import('../modules/api-info/ApiInfoPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/services' />} />
        <Route
          path='services/*'
          element={
            <SuspensedView>
              <ServicesPage />
            </SuspensedView>
          }
        />

        <Route
          path='recharge/*'
          element={
            <SuspensedView>
              <RechargePage />
            </SuspensedView>
          }
        />

        <Route
          path='account-histories/*'
          element={
            <SuspensedView>
              <AccountHistoriesPage />
            </SuspensedView>
          }
        />

        <Route
          path='otp-histories/*'
          element={
            <SuspensedView>
              <OtpHistoriesPage />
            </SuspensedView>
          }
        />

        <Route
          path='api-info/*'
          element={
            <SuspensedView>
              <ApiInfoPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { MainRoutes }
