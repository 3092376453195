/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { getUserByToken, register, verifyOtp } from '../core/_requests'
import { Link } from 'react-router-dom'
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components'
import { useAuth } from '../core/Auth'
import { CONSTANT } from '../../../../constant'
import { hideMail } from '../../../../_metronic/helpers/FunctionHelpers'

const initialValues = {
  email: '',
  password: '',
}

const registrationSchema = Yup.object().shape({
  email: Yup.string()
    .matches(CONSTANT.REGEX.EMAIL, 'Email không hợp lệ')
    .required('Vui lòng không để trống'),
  password: Yup.string().trim()
    .min(8, 'Mật khẩu không hợp lệ')
    .required('Vui lòng không để trống'),
})

const otpSchema = Yup.object().shape({
  otp: Yup.string()
    .matches(CONSTANT.REGEX.OTP, 'Mã bảo mật không đúng')
})

export function Registration() {
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showInputOtp, setShowInputOtp] = useState<boolean>(false);
  const [otpId, setOtpId] = useState<string>('');

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const { data } = await register(values.email, values.password)
        setSubmitting(false)
        setLoading(false)

        if (data?.error?.message?.[0]) {
          setStatus(data?.error?.message?.[0])
          return;
        }

        setOtpId(data.data)
        setShowInputOtp(true)
      } catch (error) {
        console.error(error)
        setStatus('The registration details is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const otpFormik = useFormik({
    initialValues: { otp: '' },
    validationSchema: otpSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const { data } = await verifyOtp(values.otp, otpId)
        setSubmitting(false)
        setLoading(false)

        if (data?.error?.message?.[0]) {
          setStatus(data?.error?.message?.[0])
          return;
        }

        saveAuth(data)
        setCurrentUser(data.data.data)
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The registration details is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <div>
      <div className='fv-row mb-3'>
        <div className='col-xl-12 text-align-center'>
          <label className='form-label fs-2 fw-bolder text-dark'>ĐĂNG KÝ</label>
        </div>
      </div>

      {
        !showInputOtp && <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_signup_form'
          onSubmit={formik.handleSubmit}
        >
          {formik.status && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}

          {/* begin::Form group Email */}
          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6'>Email</label>
            <input
              placeholder='Nhập email'
              type='email'
              autoComplete='off'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control form-control-lg',
                // { 'is-invalid': formik.touched.email && formik.errors.email },
                // { 'is-valid': formik.touched.email && !formik.errors.email }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Password */}
          <div className='mb-10 fv-row' data-kt-password-meter='true'>
            <div className='mb-1'>
              <label className='form-label fw-bolder text-dark fs-6'>Mật khẩu</label>
              <div className='position-relative mb-3'>
                <input
                  type={showPassword ? 'text' : 'password'}
                  placeholder='Nhập mật khẩu'
                  autoComplete='off'
                  {...formik.getFieldProps('password')}
                  className={clsx(
                    'form-control form-control-lg password',
                    // { 'is-invalid': formik.touched.password && formik.errors.password },
                    // { 'is-valid': formik.touched.password && !formik.errors.password }
                  )}
                />
                <i
                  className={`fa fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'} icon-5x position-absolute`}
                  style={{ fontSize: '18px', right: '10px', top: '13px' }}
                  onClick={() => setShowPassword(!showPassword)}
                ></i>
                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.password}</span>
                    </div>
                  </div>
                )}
              </div>

            </div>
            <div className='text-muted'>
              Mật khẩu cần tối thiểu 8 ký tự và không chứa khoảng cách
            </div>
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-primary w-100 mb-5'
            // disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
            >
              {!loading && <span className='indicator-label fw-bold'>Đăng ký</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Vui lòng đợi...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Form group */}
        </form>
      }

      {
        showInputOtp && <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_otp_form'
          onSubmit={otpFormik.handleSubmit}
        >
          {otpFormik.status && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{otpFormik.status}</div>
            </div>
          )}

          <div className='mb-5 fv-row'>
            <label className='form-label fs-5 fw-bolder text-dark'>
              Vui lòng nhập mã bảo mật đã được gửi tới email {hideMail(formik.values.email)}
            </label>
          </div>

          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6'>Mã bảo mật</label>
            <input
              placeholder='Nhập mã bảo mật'
              type='text'
              autoComplete='off'
              autoFocus={true}
              {...otpFormik.getFieldProps('otp')}
              className={clsx(
                'form-control form-control-lg',
                // { 'is-invalid': formik.touched.email && formik.errors.email },
                // { 'is-valid': formik.touched.email && !formik.errors.email }
              )}
            />
            {otpFormik.touched.otp && otpFormik.errors.otp && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{otpFormik.errors.otp}</span>
                </div>
              </div>
            )}
          </div>

          <div className='text-center'>
            <button
              type='submit'
              className='btn btn-lg btn-primary w-100 mb-5'
              // disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
            >
              {!loading && <span className='indicator-label fw-bold'>Hoàn thành</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Vui lòng đợi...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      }

      <div className='text-center'>
        <div className='text-gray-600 fw-bold fs-4'>
          Đã có tài khoản?
          <Link to='/auth/login' className='link-primary fw-bolder' style={{ marginLeft: '5px' }}>
            Đăng nhập
          </Link>
        </div>
      </div>
    </div>
  )
}
