const CONSTANT = {
  GOOGLE_SITE_KEY: '6LchdP0kAAAAAN-v11MX-CBE5zV6FiDKU-hSQ7yB',
  DATETIME_FORMAT: 'HH:mm:ss DD-MM-YYYY',
  DATE_FORMAT: 'DD-MM-YYYY',
  ALL: 'ALL',
  REGEX: {
    PHONE: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
    // EMAIL: /^[\w-\.]{3,50}@meeyland.com$/,
    EMAIL: /^([a-zA-Z0-9._%-]{1,64}@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/,
    OTP: /^[a-zA-Z0-9]{6}$/,
    CONTENT: /^[a-z0-9A-Z ]*$/
  },
  RESPONSE_CODE: {
    SUCCESS: 2000,
    ERROR_OTP: 2008,
    BAR_REQUEST: 400,
    UNAUTHORIZED: 401,
    SERVER_ERROR: 500
  },
  ACTIVE: {
    YES: 1,
    NO: 0
  },
  IS_ADMINISTRATOR: {
    YES: 1,
    NO: 0
  },
  ACTION: {
    LOCK: 'LOCK',
    CLICK_OUT_OF_DATE: 'CLICK_OUT_OF_DATE',
    SHOW_AREA: 'SHOW_AREA',
    SHOW_REGION: 'SHOW_REGION',
    SHOW_OFFICE: 'SHOW_OFFICE',
    ADD_OR_EDIT_NORM: 'ADD_OR_EDIT_NORM'
  },
  PAGING: {
    PAGE: 1,
    LIMIT: 10,
    DEFAULT_LIMIT: 20
  },
  TRANSACTION_TYPE: {
    PLUS: 1,
    DEDUCTION: 2,
    REFUND: 3
  },
  ROLE: {
    SUPER_ADMIN: 'super-admin',
    CHIEF_OFFICE: 'chief-office',
    MANAGER: 'manager',
    REPRESENTATIVE_OFFICE: 'representative',
    LEADER: 'leader'
  },
  REGION: {
    NORTHERN: 'northern',
    MIDDLE: 'middle',
    SOUTHERN: 'southern'
  },
  TYPE_DATE: {
    DAY: 'DAY',
    WEEK: 'WEEK',
    MONTH: 'MONTH',
    QUARTER: "QUARTER",
    YEAR: 'YEAR',
  },
  FORMAT_DATE: {
    DDMMYYYY: 'DDMMYYYY',
    MMYYYY: 'MMYYYY',
    MM_YYYY: 'MM\/YYYY',
    DD_MM_YYYY: 'DD-MM-YYYY',
    YYYY: 'YYYY'
  },
  OVERDUE_STATUS: {
    ACTIVE: 'active',
    CANCEL: 'cancel'
  },
  PERIOD_STATUS: {
    PAID: 'paid',
    WAIT_PAY: 'wait_pay',
    OVERDUE: 'overdue'
  },
  OTP_STATUS: {
    PENDING: 1,
    SCCESS: 2,
    FAIL: 3
  },
  DATE: {
    DAY: 'ngày',
    WEEK: 'tuần',
    MONTH: 'tháng'
  },
  PACKAGE_TYPE: {
    REGISTRATION: 'registration',
    RE_INVEST: 're_invest',
    NEW_INVEST: 'new_invest'
  },
  FUNC_TYPE: {
    SUPER_ADMIN_PARTNER: 'SUPER_ADMIN_PARTNER',
    CHIEF_OFFICE_PARTNER: 'CHIEF_OFFICE_PARTNER',
    MANAGER_PARTNER: 'MANAGER_PARTNER',
    REPRESENTATIVE_OFFICE_PARTNER: 'REPRESENTATIVE_OFFICE_PARTNER',

    SUPER_ADMIN_INSTALLMENT: 'SUPER_ADMIN_INSTALLMENT',
    CHIEF_OFFICE_INSTALLMENT: 'CHIEF_OFFICE_INSTALLMENT',
    MANAGER_INSTALLMENT: 'MANAGER_INSTALLMENT',
    REPRESENTATIVE_OFFICE_INSTALLMENT: 'REPRESENTATIVE_OFFICE_INSTALLMENT',

    SUPER_ADMIN_REGION: 'SUPER_ADMIN_REGION',
    SUPER_ADMIN_AREA: 'SUPER_ADMIN_AREA',
    SUPER_ADMIN_OFFICE: 'SUPER_ADMIN_OFFICE',

    CHIEF_OFFICE_AREA: 'CHIEF_OFFICE_AREA',
    CHIEF_OFFICE_OFFICE: 'CHIEF_OFFICE_OFFICE',

    MANAGER_OFFICE: 'MANAGER_OFFICE',
  },
  PACKAGE_TYPE_TITLE: {
    REGISTRATION: 'ID mới',
    RE_INVEST: 'ID tái góp',
    NEW_INVEST: 'ID góp mới'
  },
  ID_TYPE_TITLE: [
    { id: 'REGISTRATION', name: 'ID mới'},
    { id: 'NEW_CONTRIBUTION', name: 'ID góp mới'},
    { id: 'NO_CONTRIBUTION', name: 'ID mới chưa góp'}
  ],
  SELECT_ABOUT_TIME: [
    { key: 'month', value: '6 tháng'},
    { key: 'year', value: '1 năm'}
  ],
  TARGET_MODIFY_TIME: {
    PUBLIC: 'PUBLIC',
    LIMIT: 'LIMIT',
  },
  TARGET_MODIFY_COUNT: 1,
  ADMININSTRATIVE_UNIT: {
    REGION: 'REGION',
    AREA: 'AREA',
    OFFICE: 'OFFICE'
  },
  CURRENCY: {
    KEY: 'CURRENCY',
    USD: 'USD',
    VND: 'VND'
  },
  META_TYPE: {
    REVENUE_NORM: 'REVENUE_NORM'
  },
  MAP_MONTHS: {
    jan: 1,
    feb: 2, 
    mar: 3, 
    apr: 4,
    may: 5,
    jun: 6,
    july: 7,
    aug: 8,
    sep: 9,
    oct: 10,
    nov: 11,
    dec: 12
  }
}

export { CONSTANT };
