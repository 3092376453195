import { Dispatch, SetStateAction } from 'react'
import { Row } from 'react-table'

export type ID = undefined | null | number | string

export type PaginationState = {
  page: number
  pageCount?: number
  items_per_page: 10 | 20 | 30 | 50 | 100
  limit: 10 | 20 | 50 | 100
  links?: Array<{ label: string; active: boolean; url: string | null; page: number | null }>
  total?: number
}

export type SortState = {
  sort?: string
  order?: 'asc' | 'desc'
}

export type FilterState = {
  filter?: unknown
}

export type SearchState = {
  search?: string
}

export type Pagination = {
  page: number,
  pageCount: number,
  limit: 10 | 20 | 50 | 100,
  total?: number
}

export type error = {
  code: number,
  message: Array<String> | String,
}

export type Response<T> = {
  data?: T
  payload?: {
    message?: string
    errors?: {
      [key: string]: Array<string>
    }
    pagination?: PaginationState
  },
  error?: error
  paging: PaginationState,
  status: boolean
}

export type QueryState = PaginationState & SortState & FilterState & SearchState
export type QueryStateData = FilterState & SearchState

export type QueryRequestContextProps = {
  state: QueryState
  updateState: (updates: Partial<QueryState>) => void
}

export type QueryRequestDataProps = {
  state: QueryStateData
  updateState: (updates: Partial<QueryStateData>) => void
}

export const initialQueryState: QueryState = {
  page: 1,
  items_per_page: 10,
  limit: 10,
}

export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => { },
}

export const initialQueryDataRequest: QueryRequestDataProps = {
  state: {},
  updateState: () => { },
}


export type QueryResponseContextProps<T> = {
  response?: Response<Array<T>> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}

export type QueryResponseDataProps<T> = {
  response?: Response<T> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}

export const initialQueryResponse = { refetch: () => { }, isLoading: false, query: '' }

export type ListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  itemIdForDetail?: ID
  setItemIdForDetail?: Dispatch<SetStateAction<ID>>
  itemIdForDelete?: ID
  setItemIdForDelete?: Dispatch<SetStateAction<ID>>
  itemIdForResetPassword?: ID
  setItemIdForResetPassword?: Dispatch<SetStateAction<ID>>
  itemName?: String
  setItemName?: Dispatch<SetStateAction<String>>
  itemId?: ID
  setItemId?: Dispatch<SetStateAction<ID>>
  action?: String
  setAction?: Dispatch<SetStateAction<String>>
  row?: Row,
  setRow?: Dispatch<SetStateAction<Row>>
  isAllSelected: boolean
  disabled: boolean

  regionIds?: Array<ID>
  setRegionIds?: Dispatch<SetStateAction<Array<ID>>>

  areaIds?: Array<ID>
  setAreaIds?: Dispatch<SetStateAction<Array<ID>>>

  month?: String
  setMonth?: Dispatch<SetStateAction<String>>

  revenueNorm?: number
  setRevenueNorm?: Dispatch<SetStateAction<number>>
}

export const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemIdForUpdate: () => { },
  setItemIdForDetail: () => { },
  setItemIdForDelete: () => { },
  setItemIdForResetPassword: () => { },
  setItemId: () => { },
  setAction: () => { },
  setRow: () => { },
  isAllSelected: false,
  disabled: false,
  regionIds: [],
  setRegionIds: () => { },
  areaIds: [],
  setAreaIds: () => { },
  setMonth: () => { },
  setRevenueNorm: () => { },
}

export type DataViewContextProps = {
  itemId?: ID
  setItemId?: Dispatch<SetStateAction<ID>>
  action?: String
  setAction?: Dispatch<SetStateAction<String>>
  data?: Object
  setData?: Dispatch<SetStateAction<Object>>
}

export const initialDataView: DataViewContextProps = {
  setItemId: () => { },
  setAction: () => { },
  setData: () => { },
}
