/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { getUserByToken, login } from '../core/_requests'
import { useAuth } from '../core/Auth'
import { CONSTANT } from '../../../../constant'
import ReCAPTCHA from 'react-google-recaptcha'
import { Link } from 'react-router-dom'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .required('Vui lòng không để trống')
    .matches(CONSTANT.REGEX.EMAIL, 'Email không hợp lệ'),
  password: Yup.string().required('Vui lòng không để trống'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [captcha, setCaptcha] = useState<string>('');

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const { data: auth } = await login(values.email, values.password);

        if (auth?.error?.message?.[0]) {
          saveAuth(undefined)
          setStatus(auth?.error?.message?.[0])
          setSubmitting(false)
          setLoading(false)
          return;
        }
        saveAuth(auth)
        const { data } = await getUserByToken(auth.data.access_token);
        setCurrentUser(data.data)

        // window.location.reload()
      } catch (error) {
        console.error('error :', error)
        saveAuth(undefined)
        setStatus('Email/Mật khẩu không đúng')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const changeCapcha = (value: string | null) => {
    console.log("Captcha value:", value);
    setCaptcha(value || '')
  }

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <div className='fv-row mb-3'>
        <div className='col-xl-12 text-align-center'>
          <label className='form-label fs-2 fw-bolder text-dark'>ĐĂNG NHẬP</label>
        </div>
      </div>

      {formik.status && (
        <div className='mb-lg-8 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Nhập email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg',
            // { 'is-invalid': formik.touched.email && formik.errors.email },
            // { 'is-valid': formik.touched.email && !formik.errors.email }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
        {/* <InputText
          fieldName="email"
          formik={formik}
          autoFocus={false}
          placeholder="Email"
          type="email"
          classNameValidate={false}
          onChange={(e => {
            formik.setFieldValue('email', e.target.value)
          })}
        /> */}
      </div>

      <div className='fv-row mb-5 position-relative'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Mật khẩu</label>
          </div>
        </div>
        <input
          type={showPassword ? 'text' : 'password'}
          autoComplete='off'
          placeholder='Nhập mật khẩu'
          {...formik.getFieldProps('password')}
          className={clsx('form-control form-control-lg password')}
        />
        <i
          className={`fa fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'} icon-5x position-absolute`}
          style={{ fontSize: '18px', right: '10px', top: '40px' }}
          onClick={() => setShowPassword(!showPassword)}
        ></i>
        {/* <i className="la la-close icon-5x position-absolute cursor-pointer"
          style={{ fontSize: '20px', right: '40px', top: '40px' }}
          onClick={() => formik.setFieldValue('password', '')}
        ></i> */}
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>

      <div className='text-left'>
        <div className='text-gray-600 fw-bold fs-4 mb-5'>
          <Link to='/auth/forgot-password' className='link-primary fw-bolder' style={{ marginLeft: '5px' }}>
            Quên mật khẩu?
          </Link>
        </div>
      </div>

      <div className='fv-row mb-10'>
        <ReCAPTCHA sitekey={CONSTANT.GOOGLE_SITE_KEY} onChange={changeCapcha} />
      </div>

      <div className='text-center mb-5'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100'
          disabled={formik.isSubmitting || !captcha || captcha == ''}
        // disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label fw-bold '>Đăng nhập</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Vui lòng đợi...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>

      <div className='text-center'>
        {/* <h1 className='text-dark mb-3'>Create an Account</h1> */}
        <div className='text-gray-600 fw-bold fs-4'>
          Chưa có tài khoản?
          <Link to='/auth/registration' className='link-primary fw-bolder' style={{ marginLeft: '5px' }}>
            Đăng ký
          </Link>
        </div>
      </div>

    </form>
  )
}
