/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { changePassword, getUserByToken, register, verifyEmail, verifyOtp, verifyOtpPwd } from '../core/_requests'
import { Link, useNavigate } from 'react-router-dom'
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components'
import { useAuth } from '../core/Auth'
import { CONSTANT } from '../../../../constant'
import { hideMail } from '../../../../_metronic/helpers/FunctionHelpers'
import { toast } from 'react-toastify'

const initialValues = { email: '' }

const verifyEmailSchema = Yup.object().shape({
  email: Yup.string()
    .matches(CONSTANT.REGEX.EMAIL, 'Email không hợp lệ')
    .required('Vui lòng không để trống')
})

const otpSchema = Yup.object().shape({
  otp: Yup.string()
    .matches(CONSTANT.REGEX.OTP, 'Mã bảo mật không đúng')
    .required('Vui lòng không để trống')
})

const changePasswordSchema = Yup.object().shape({
  password: Yup.string().trim()
    .min(8, 'Mật khẩu không hợp lệ')
    .required('Vui lòng không để trống'),
  confirm_password: Yup.string()
    .required('Vui lòng không để trống')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Mật khẩu nhập không khớp"),
    }),
})


export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);
  const [otpId, setOtpId] = useState<string>('');
  const [token, setToken] = useState<string>('');
  const navigate = useNavigate();

  const formikVerifyEmail = useFormik({
    initialValues,
    validationSchema: verifyEmailSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const { data } = await verifyEmail(values.email)
        setSubmitting(false)
        setLoading(false)

        if (data?.error?.message?.[0]) {
          setStatus(data?.error?.message?.[0])
          return;
        }

        setOtpId(data.data)
        setStep(2)
      } catch (error) {
        console.error(error)
        // setStatus('The registration details is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const otpFormik = useFormik({
    initialValues: { otp: '' },
    validationSchema: otpSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const { data } = await verifyOtpPwd(values.otp, otpId)
        setSubmitting(false)
        setLoading(false)

        if (data?.error?.message?.[0]) {
          setStatus(data?.error?.message?.[0])
          return;
        }

        setToken(data.data)
        setStep(3)
      } catch (error) {
        console.error(error)
        // setStatus('The registration details is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const updatePasswordFormik = useFormik({
    initialValues: { password: '', confirm_password: '' },
    validationSchema: changePasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const { data } = await changePassword(token, values.password, values.confirm_password)
        setSubmitting(false)
        setLoading(false)

        if (data?.error?.message?.[0]) {
          setStatus(data?.error?.message?.[0])
          return;
        }

        toast.success('Thay đổi mật khẩu thành công')
        navigate('/auth/login')
      } catch (error) {
        console.error(error)
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <div>
      <div className='fv-row mb-3'>
        <div className='col-xl-12 text-align-center'>
          <label className='form-label fs-2 fw-bolder text-dark'>QUÊN MẬT KHẨU</label>
        </div>
      </div>

      {
        (step == 1) && <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_signup_form'
          onSubmit={formikVerifyEmail.handleSubmit}
        >
          {formikVerifyEmail.status && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formikVerifyEmail.status}</div>
            </div>
          )}

          {/* begin::Form group Email */}
          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6'>Email</label>
            <input
              placeholder='Nhập email'
              type='email'
              autoComplete='off'
              {...formikVerifyEmail.getFieldProps('email')}
              className={clsx(
                'form-control form-control-lg',
                // { 'is-invalid': formik.touched.email && formik.errors.email },
                // { 'is-valid': formik.touched.email && !formik.errors.email }
              )}
            />
            {formikVerifyEmail.touched.email && formikVerifyEmail.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formikVerifyEmail.errors.email}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-primary w-100 mb-5'
            // disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
            >
              {!loading && <span className='indicator-label fw-bold'>Xác nhận</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Vui lòng đợi...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Form group */}
        </form>
      }

      {
        (step == 2) && <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_otp_form'
          onSubmit={otpFormik.handleSubmit}
        >
          {otpFormik.status && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{otpFormik.status}</div>
            </div>
          )}

          <div className='mb-5 fv-row'>
            <label className='form-label fs-5 fw-bolder text-dark'>
              Vui lòng nhập mã bảo mật đã được gửi tới email {hideMail(formikVerifyEmail.values.email)}
            </label>
          </div>

          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6'>Mã bảo mật</label>
            <input
              placeholder='Nhập mã bảo mật'
              type='text'
              autoComplete='off'
              autoFocus={true}
              {...otpFormik.getFieldProps('otp')}
              className={clsx(
                'form-control form-control-lg',
                // { 'is-invalid': formik.touched.email && formik.errors.email },
                // { 'is-valid': formik.touched.email && !formik.errors.email }
              )}
            />
            {otpFormik.touched.otp && otpFormik.errors.otp && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{otpFormik.errors.otp}</span>
                </div>
              </div>
            )}
          </div>

          <div className='text-center'>
            <button
              type='submit'
              className='btn btn-lg btn-primary w-100 mb-5'
            // disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
            >
              {!loading && <span className='indicator-label fw-bold'>Xác thực</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Vui lòng đợi...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      }

      {
        (step == 3) && <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_otp_form'
          onSubmit={updatePasswordFormik.handleSubmit}
        >
          {updatePasswordFormik.status && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{updatePasswordFormik.status}</div>
            </div>
          )}

          <div className='mb-5 fv-row'>
            <label className='form-label fs-5 fw-bolder text-dark'>
              Vui lòng nhập mật khẩu mới
            </label>
          </div>

          <div className='fv-row mb-7 position-relative'>
            <label className='form-label fw-bolder text-dark fs-6'>Mật khẩu</label>
            <input
              placeholder='Nhập mật khẩu'
              type={showPassword ? 'text' : 'password'}
              autoComplete='off'
              autoFocus={true}
              {...updatePasswordFormik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg',
                // { 'is-invalid': formik.touched.email && formik.errors.email },
                // { 'is-valid': formik.touched.email && !formik.errors.email }
              )}
            />
            <i
              className={`fa fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'} icon-5x position-absolute`}
              style={{ fontSize: '18px', right: '10px', top: '40px' }}
              onClick={() => setShowPassword(!showPassword)}
            ></i>
            {updatePasswordFormik.touched.password && updatePasswordFormik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{updatePasswordFormik.errors.password}</span>
                </div>
              </div>
            )}
            <div className='text-muted'>
              Mật khẩu cần tối thiểu 8 ký tự và không chứa khoảng cách
            </div>
          </div>

          <div className='fv-row mb-7 position-relative'>
            <label className='form-label fw-bolder text-dark fs-6'>Nhập lại mật khẩu</label>
            <input
              placeholder='Nhập lại mật khẩu'
              type={showConfirmPassword ? 'text' : 'password'}
              autoComplete='off'
              {...updatePasswordFormik.getFieldProps('confirm_password')}
              className={clsx(
                'form-control form-control-lg',
                // { 'is-invalid': formik.touched.email && formik.errors.email },
                // { 'is-valid': formik.touched.email && !formik.errors.email }
              )}
            />
            <i
              className={`fa fas ${showConfirmPassword ? 'fa-eye-slash' : 'fa-eye'} icon-5x position-absolute`}
              style={{ fontSize: '18px', right: '10px', top: '40px' }}
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            ></i>
            {updatePasswordFormik.touched.confirm_password && updatePasswordFormik.errors.confirm_password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{updatePasswordFormik.errors.confirm_password}</span>
                </div>
              </div>
            )}
          </div>

          <div className='text-center'>
            <button
              type='submit'
              className='btn btn-lg btn-primary w-100 mb-5'
            // disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
            >
              {!loading && <span className='indicator-label fw-bold'>Xác thực</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Vui lòng đợi...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      }

      <div className='text-center'>
        <div className='text-gray-600 fw-bold fs-4'>
          Đã có tài khoản?
          <Link to='/auth/login' className='link-primary fw-bolder' style={{ marginLeft: '5px' }}>
            Đăng nhập
          </Link>
        </div>
      </div>
    </div>
  )
}
