import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../../../_metronic/helpers'
import { BalanceInfoModel } from './_models'
import { BALANCE_URL } from '../../../../../endpoint'

const getBalanceInfo = (): Promise<Response<BalanceInfoModel> | undefined> => {
  return axios
    .get(`${BALANCE_URL}/info`)
    .then((response: AxiosResponse<Response<BalanceInfoModel>>) => response.data)
}

export {
  getBalanceInfo
}
