const API_URL = process.env.REACT_APP_API_URL
const OFFICE_URL = `${API_URL}/office`
const AREA_URL = `${API_URL}/area`
const USER_URL = `${API_URL}/user`
const ROLE_URL = `${API_URL}/role`
const REGION_URL = `${API_URL}/region`
const SERVICE_URL = `${API_URL}/service`
const BALANCE_URL = `${API_URL}/balance`


const REPORT_URL = `${API_URL}/report`
const REPORT_CHART_URL = `${API_URL}/report-chart`
const CUSTOMER_URL = `${API_URL}/customer`
const EXPORT_URL = `${API_URL}/export`

export  {
  API_URL,
  OFFICE_URL,
  AREA_URL,
  USER_URL,
  ROLE_URL,
  REGION_URL,
  REPORT_URL,
  REPORT_CHART_URL,
  CUSTOMER_URL,
  EXPORT_URL,
  SERVICE_URL,
  BALANCE_URL
}
