import { toast } from 'react-toastify';
import { AuthModel } from './_models'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: { headers: { Authorization: string } }) => {
      const auth = getAuth()
      if (auth && auth.data.access_token) {
        // config.headers.Authorization = `Bearer ${auth.api_token}`
        config.headers.Authorization = auth.data.access_token
      }

      return config
    },
    (err: any) => {
      if (err.response.status === 401) {
        console.log(401);
        // logout
      }

      return Promise.reject(err)
    }
  )

  axios.interceptors.response.use((response: any) => {
    return response;
  }, (error: any) => {
    if (error.response.status === 401) {
      toast.warning('Phiên làm việc của bạn đã hết hạn!')
      removeAuth();
      window.location.reload();
    } else if (error.response.status === 403) {
      // toast.error('Bạn không có quyền thực hiện chức năng này!')
    }else {
      return Promise.reject(error);
    }
  });
}

export { getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY }
