import axios from 'axios'
import { CONSTANT } from '../../../../constant'
import { AuthModel, VerifyModel, VerifyTokenModel } from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/validate-token`
export const VERIFY_OTP_URL = `${API_URL}/auth/verify-otp`
export const VERIFY_OTP_PWD_URL = `${API_URL}/auth/verify-otp-pwd`
export const VERIFY_EMAIL_URL = `${API_URL}/auth/verify-email`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REGISTER_URL = `${API_URL}/auth/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const CHANGE_PASSWORD_URL = `${API_URL}/auth/change-password`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    loginName: email,
    password,
    type: 'email'
  })
}

// Server should return AuthModel
export function register(
  email: string,
  password: string
) {
  return axios.post(REGISTER_URL, {
    loginName: email,
    password,
    type: 'email'
  })
}

export function changePassword(
  token: string,
  password: string,
  confirmPassword: string
) {
  return axios.post(CHANGE_PASSWORD_URL, {
    token,
    password,
    confirm_password: confirmPassword
  })
}


// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function verifyOtp(otp: string, otpId: string) {
  return axios.post<AuthModel>(VERIFY_OTP_URL, {
    otp,
    otp_id: otpId
  })
}

export function verifyOtpPwd(otp: string, otpId: string) {
  return axios.post<VerifyModel>(VERIFY_OTP_PWD_URL, {
    otp,
    otp_id: otpId
  })
}

export function verifyEmail(email: string) {
  return axios.post<VerifyModel>(VERIFY_EMAIL_URL, { email })
}

export function getUserByToken(token: string) {
  return axios.get<VerifyTokenModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: token
    }
  });
}
