const QUERIES = {
  USERS_LIST: 'users-list',
  GET_USER_BY_ID: 'get-user-by-id',
  REGIONS_LIST: 'regions-list',
  SERVICES: 'services',
  BALANCES: 'balances',
  OTP: 'otp',
}

export { QUERIES }
