/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { AsideMenuItem } from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      {/* <div className='menu-item'>
        <div className='menu-content pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Quản lý khối</span>
        </div>
      </div> */}

      <AsideMenuItem
        to='/services'
        title='Đăng ký dịch vụ'
        icon='/media/svg/custom/icn_account.svg'
        fontIcon='bi-person'
      >
      </AsideMenuItem>

      <AsideMenuItem
        to='/recharge'
        title='Nạp tiền'
        icon='/media/svg/custom/icn_account.svg'
        fontIcon='bi-person'
      >
      </AsideMenuItem>

      <AsideMenuItem
        to='/account-histories'
        title='Lịch sử tài khoản'
        icon='/media/svg/custom/icn_account.svg'
        fontIcon='bi-person'
      >
      </AsideMenuItem>

      <AsideMenuItem
        to='/otp-histories'
        title='Lịch sử otp'
        icon='/media/svg/custom/icn_account.svg'
        fontIcon='bi-person'
      >
      </AsideMenuItem>

      <AsideMenuItem
        to='/api-info'
        title='Thông tin API'
        icon='/media/svg/custom/icn_account.svg'
        fontIcon='bi-person'
      >
      </AsideMenuItem>

      {/* <AsideMenuItemWithSub
        to='/report-sale'
        title='Báo cáo doanh số'
        icon='/media/svg/custom/icn_revenue.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/report-sale/report-manager' title='Báo cáo quản lý' hasBullet={true} />
        <AsideMenuItem to='/report-sale/region-office' title='Vùng miền - văn phòng' hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/manage-client'
        title='Quản lý khách hàng'
        icon='/media/svg/custom/icn_partner.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/manage-client/report-new-client' title='Báo cáo số lượng KH mới' hasBullet={true} />
        <AsideMenuItem to='/manage-client/client-category' title='Danh mục khách hàng' hasBullet={true} />
      </AsideMenuItemWithSub> */}

      {/* <AsideMenuItem
        to='/sales'
        icon='/media/svg/custom/icn_revenue.svg'
        title='Doanh số'
        fontIcon='bi-layers'
      /> */}
      {/* <AsideMenuItem
        to='/partners'
        // icon='/media/icons/duotune/general/gen019.svg'
        icon='/media/svg/custom//icn_partner.svg'
        title='Đối tác'
        fontIcon='bi-layers'
      /> */}
      {/* <AsideMenuItem
        to='/installment-package'
        // icon='/media/icons/duotune/art/art002.svg'
        icon='/media/svg/custom//icn_poll.svg'
        title='Gói trả góp'
        fontIcon='bi-layers'
      />
      <AsideMenuItemWithSub
        to='/account-management'
        title='Quản lý tài khoản'
        // icon='/media/icons/duotune/communication/com006.svg'
        icon='/media/svg/custom/icn_account.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/account-management/accounts' title='Quản lý tài khoản' hasBullet={true} />
        <AsideMenuItem to='/account-management/roles' title='Quản lý chức danh' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItem
        to='/region-management/regions'
        // icon='/media/icons/duotune/general/gen019.svg'
        icon='/media/svg/custom/icn_location.svg'
        title='Vùng miền - Văn phòng'
        fontIcon='bi-layers'
      /> */}
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
    </>
  )
}
